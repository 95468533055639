import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {catchError, tap} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import { LocalStorageRefService } from './LocalStorageRefService';
import {TZApiLogin, TZDataVersion_Req, TZDataVersion_Res} from '../interfaces/tzapi';
import {
  TZUserAnswerQuestion,
  TZUserAnswerTotal,
  TZUserAppLocalInfo,
  TZUserFavTotal,
  TZUserProfile, TZUserProfile_MedicalHistoryForm,
  TZUserQuestionSuggest
} from '../interfaces/userProfile';
import {UserProfileService} from './user-profile.service';
import {UtilService} from '../services/util.service';
import {EvaluateData} from './evaluate-data';
import {TZQuestionTotal} from '../interfaces/question';
import {Observable} from 'rxjs';
import {TZResponse} from '../interfaces/other';

@Injectable({
  providedIn: 'root'
})
export class TzapiServiceService {

  apiServer="";

  constructor(
    public http: HttpClient,
    public utilService: UtilService,
    public localStorageRefService: LocalStorageRefService,
    public evaluateData: EvaluateData,

  ) {
    this.apiServer=environment.apiServer;
    // apiServer=apiServer+"/tzapi/testPost"
    this.apiServer=this.apiServer+"/api/tzapi"

  }

  testPost(data){
    let postData={
      data:data,
    }

    return this.apiCaller("/testPost",postData)
  }

  Login(email,password){
    let postData={
      email:email,
      password:password,
    }

    return this.apiCaller("/Login",postData)
      .pipe(tap(
        s=>{
          let accessToken = s.access_token;
          this.localStorageRefService.AccessToken_setData(accessToken);
        }
      ));
  }

  UserInfo(){
    return this.apiCaller("/UserInfo",null)
  }

  Logout(){
    return this.apiCaller("/Logout",null);
  }

  Register(name,email,password,area){
    let postData={
      name:name,
      email:email,
      password:password,
      area:area
    }
    return this.apiCaller("/Register",postData);
  }

  ResetPassword(phone){
    let postData={
      phone:phone,
    }

    return this.apiCaller("/ResetPassword",postData);
  }

  ChangePassword(name,password,area){
    let postData={
      name:name,
      password:password,
      area:area
    }

    return this.apiCaller("/ChangePassword",postData);
  }

  //---------------------

  DataVersion_Check(req:TZDataVersion_Req):Observable<TZResponse<TZDataVersion_Res>>{
    let postData={
      json:JSON.stringify(req),
    }

    return this.apiCaller("/DataVersion_Check",postData);
  }


  CheckAppVersion(version){
    let postData={
      version:version,
    }

    return this.apiCaller("/CheckAppVersion",postData);
  }


  //---------------------

  QuestionTotal_Get(){
    return this.apiCaller("/QuestionTotal_Get",null);
  }

  //以Server為準，不需要此method
  // QuestionTotal_Save(tzQuestionTotalTotal:TZQuestionTotal){
  //   let postData={
  //     json:JSON.stringify(tzQuestionTotalTotal),
  //   }
  //
  //   return this.apiCaller("/QuestionTotal_Save",postData).subscribe((res)=>{
  //   });
  // }

  //---------------------

  KnowledgeTotal_Get(){
    return this.apiCaller("/KnowledgeTotal_Get",null);
  }

  //以Server為準，不需要此method
  // KnowledgeTotal_Save(tzKnowledgeTotalTotal:TZKnowledgeTotal){
  //   let postData={
  //     json:JSON.stringify(tzKnowledgeTotalTotal),
  //   }
  //
  //   return this.apiCaller("/KnowledgeTotal_Save",postData).subscribe((res)=>{
  //   });
  // }

  //---------------------

  DoctorTotal_Get(){
    return this.apiCaller("/DoctorTotal_Get",null);
  }

  //以Server為準，不需要此method
  // DoctorTotal_Save(tzDoctorTotalTotal:TZDoctorTotal){
  //   let postData={
  //     json:JSON.stringify(tzDoctorTotalTotal),
  //   }
  //
  //   return this.apiCaller("/DoctorTotal_Save",postData).subscribe((res)=>{
  //   });
  // }

  //---------------------

  UserAnswer_Get(){
    return this.apiCaller("/UserAnswer_Get",null);
  }

  UserAnswer_Save(tzUserAnswerTotal:TZUserAnswerTotal):Observable<TZResponse<TZUserQuestionSuggest>>{
    let postData={
      json:JSON.stringify(tzUserAnswerTotal),
    }

    return this.apiCaller("/UserAnswer_Save",postData);
  }
  //---------------------

  UserAppLocalInfo_Get(){
    return this.apiCaller("/UserAppLocalInfo_Get",null);
  }

  UserAppLocalInfo_Save(tzUserAppLocalInfo:TZUserAppLocalInfo){
    let postData={
      json:JSON.stringify(tzUserAppLocalInfo),
    }

    return this.apiCaller("/UserAppLocalInfo_Save",postData).subscribe((res)=>{
    });
  }

  //---------------------

  UserFav_Get(){
    return this.apiCaller("/UserFav_Get",null);
  }

  UserFav_Save(tzUserFavTotal:TZUserFavTotal){
    let postData={
      json:JSON.stringify(tzUserFavTotal),
    }


    return this.apiCaller("/UserFav_Save",postData).subscribe((res)=>{
    });
  }
  //---------------------

  UserProfile_Get(){
    return this.apiCaller("/UserProfile_Get",null);
  }

  UserProfile_Save(tzUserProfile:TZUserProfile){
    let postData={
      json:JSON.stringify(tzUserProfile),
    }

    return this.apiCaller("/UserProfile_Save",postData)
  }
  //---------------------

  UserProfile_MedicalHistoryForm_Get(){
    return this.apiCaller("/UserProfile_MedicalHistoryForm_Get",null);
  }

  UserProfile_MedicalHistoryForm_Save(tzUserProfile_MedicalHistoryForm:TZUserProfile_MedicalHistoryForm){
    let postData={
      json:JSON.stringify(tzUserProfile_MedicalHistoryForm),
    }

    return this.apiCaller("/UserProfile_MedicalHistoryForm_Save",postData)
  }
  //---------------------

  UserQuestionSuggest_Get(){
    return this.apiCaller("/UserQuestionSuggest_Get",null);
  }

  //沒有Save的需要
  // UserQuestionSuggest_Save(tzUserQuestionSuggest:TZUserQuestionSuggest){
  //   let postData={
  //     json:JSON.stringify(tzUserQuestionSuggest),
  //   }
  //
  //   return this.apiCaller("/UserQuestionSuggest_Save",postData).subscribe((res)=>{
  //   });
  // }

  //------------------
  apiCaller(methodPath,postData){

    return this.http
      .post<any>(this.apiServer +methodPath,postData)
      .pipe(tap(s=> this.showMsg(s)))
  }

  async showMsg(response){
    let msg=response.msg;

    if (msg){
      await this.utilService.showToast_Msg(msg);
    }

    let errMsg=response.errMsg;

    if (errMsg){
      await this.utilService.showToast_Alert(errMsg);
    }

  }
}
