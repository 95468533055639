import { Injectable, PLATFORM_ID, Inject } from '@angular/core'
import { isPlatformBrowser } from '@angular/common'
import {
  TZUserAnswerTotal,
  TZUserFavTotal,
  TZUserProfile,
  TZUserProfile_MedicalHistoryForm,
  TZUserQuestionSuggest
} from '../interfaces/userProfile';

function getLocalStorage() {
  return localStorage
}

@Injectable({ providedIn: 'root' })
export class LocalStorageRefService {

  constructor(@Inject(PLATFORM_ID) private platformId) {}

  get localStorage() {
    if (isPlatformBrowser(this.platformId)) {
      return getLocalStorage()
    } else {
      // use alternative or throw error
    }
  }
  public AccessToken_setData(accessToken: string) {
    this.localStorage.setItem("AccessToken", accessToken)
  }

  public AccessToken_getData() {
    return this.localStorage.getItem("AccessToken");
  }

  public IsLogined():boolean{
    if (this.AccessToken_getData()){
      return true;
    }else{
      return false;
    }
  }


  public UserInfo_setData(data) {
    const jsonData = JSON.stringify(data)
    this.localStorage.setItem("UserInfo", jsonData)
  }

  public UserInfo_getData() {
    return JSON.parse(this.localStorage.getItem("UserInfo"));
  }
  //---------------------------------------
  public QuestionTotal_haveData() {
    this.localStorage.haveData("QuestionTotal")
  }

  public QuestionTotal_setData(data) {
    const jsonData = JSON.stringify(data)
    this.localStorage.setItem("QuestionTotal", jsonData)
  }

  public QuestionTotal_getData() {
    return JSON.parse(this.localStorage.getItem("QuestionTotal"));
  }

  public QuestionTotal_removeData() {
    this.localStorage.removeItem("QuestionTotal")
  }
  //---------------------------------------
  public KnowledgeTotal_haveData() {
    this.localStorage.haveData("KnowledgeTotal")
  }

  public KnowledgeTotal_setData(data) {
    const jsonData = JSON.stringify(data)
    this.localStorage.setItem("KnowledgeTotal", jsonData)
  }

  public KnowledgeTotal_getData() {
    return JSON.parse(this.localStorage.getItem("KnowledgeTotal"));
  }

  public KnowledgeTotal_removeData() {
    this.localStorage.removeItem("KnowledgeTotal")
  }
  //---------------------------------------
  public DoctorTotal_haveData() {
    this.localStorage.haveData("DoctorTotal")
  }

  public DoctorTotal_setData(data) {
    const jsonData = JSON.stringify(data)
    this.localStorage.setItem("DoctorTotal", jsonData)
  }

  public DoctorTotal_getData() {
    return JSON.parse(this.localStorage.getItem("DoctorTotal"));
  }

  public DoctorTotal_removeData() {
    this.localStorage.removeItem("DoctorTotal")
  }

  //---------------------------------------
  public UserAppLocalInfo_haveData() {
    this.localStorage.haveData("UserAppLocalInfo")
  }

  public UserAppLocalInfo_setData(data) {
    const jsonData = JSON.stringify(data)
    this.localStorage.setItem("UserAppLocalInfo", jsonData)
  }

  public UserAppLocalInfo_getData() {
    return JSON.parse(this.localStorage.getItem("UserAppLocalInfo"));
  }

  public UserAppLocalInfo_removeData() {
    this.localStorage.removeItem("UserAppLocalInfo")
  }

  //---------------------------------------

  // public Questions_haveData() {
  //   this.localStorage.haveData("Questions")
  // }
  //
  // public Questions_setData(data) {
  //   const jsonData = JSON.stringify(data)
  //   this.localStorage.setItem("Questions", jsonData)
  // }
  //
  // public Questions_getData() {
  //   return JSON.parse(this.localStorage.getItem("Questions"));
  // }
  //
  // public Questions_removeData() {
  //   this.localStorage.removeItem("Questions")
  // }


  //---------------------------------------
  public UserQuestionSuggest_haveData() {
    this.localStorage.haveData("UserQuestionSuggest")
  }

  public UserQuestionSuggest_setData(data:TZUserQuestionSuggest) {
    const jsonData = JSON.stringify(data)
    this.localStorage.setItem("UserQuestionSuggest", jsonData)
  }

  public UserQuestionSuggest_getData() :TZUserQuestionSuggest {
    return JSON.parse(this.localStorage.getItem("UserQuestionSuggest"))
  }

  public UserQuestionSuggest_removeData() {
    this.localStorage.removeItem("UserQuestionSuggest")
  }

  //---------------------------------------
  public UserProfile_haveData() {
    this.localStorage.haveData("UserProfile")
  }

  public async UserProfile_setData(data:TZUserProfile) {
    const jsonData = JSON.stringify(data)
    this.localStorage.setItem("UserProfile", jsonData)
  }

  public UserProfile_getData() :TZUserProfile {
    return JSON.parse(this.localStorage.getItem("UserProfile"))
  }

  public UserProfile_removeData() {
    this.localStorage.removeItem("UserProfile")
  }

  //---------------------------------------
  public UserProfile_MedicalHistoryForm_haveData() {
    this.localStorage.haveData("UserProfile_MedicalHistoryForm")
  }

  public async UserProfile_MedicalHistoryForm_setData(data:TZUserProfile_MedicalHistoryForm) {
    const jsonData = JSON.stringify(data)
    this.localStorage.setItem("UserProfile_MedicalHistoryForm", jsonData)
  }

  public UserProfile_MedicalHistoryForm_getData() :TZUserProfile_MedicalHistoryForm {
    return JSON.parse(this.localStorage.getItem("UserProfile_MedicalHistoryForm"))
  }

  public UserProfile_MedicalHistoryForm_removeData() {
    this.localStorage.removeItem("UserProfile_MedicalHistoryForm")
  }

  //---------------------------------------
  public UserFav_haveData() {
    this.localStorage.haveData("UserFav")
  }

  public UserFav_setData(data:TZUserFavTotal) {
    const jsonData = JSON.stringify(data)
    this.localStorage.setItem("UserFav", jsonData)
  }

  public UserFav_getData() :TZUserFavTotal {
    return JSON.parse(this.localStorage.getItem("UserFav"))
  }

  public UserFav_removeData() {
    this.localStorage.removeItem("UserFav")
  }


  //---------------------------------------
  public UserAnswer_haveData() {
    this.localStorage.haveData("UserAnswer")
  }

  public UserAnswer_setData(data:TZUserAnswerTotal) {
    const jsonData = JSON.stringify(data)
    this.localStorage.setItem("UserAnswer", jsonData)
  }

  public UserAnswer_getData() :TZUserAnswerTotal {
    return JSON.parse(this.localStorage.getItem("UserAnswer"))
  }

  public UserAnswer_removeData() {
    this.localStorage.removeItem("UserAnswer")
  }

  //-------------------------------
  public haveData(key) {
    const data=this.getData(key);

    if (data){
      return true;
    }else{
      return false;
    }
  }

  private setData(key,data) {
    const jsonData = JSON.stringify(data)
    this.localStorage.setItem(key, jsonData)
  }

  private getData(key) {
    return this.localStorage.getItem(key)
  }

  private removeData(key) {
    this.localStorage.removeItem(key)
  }
}
