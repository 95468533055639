import { Injectable } from '@angular/core';
import {UserProfileService} from './user-profile.service';
import {TZDataVersion_Req, TZDataVersion_Res} from '../interfaces/tzapi';
import {TzapiServiceService} from './tzapi-service.service';
import {TZResponse} from '../interfaces/other';
import {merge} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataVersionCheckService {

  constructor(
    public userProfileService: UserProfileService,
    public tzapiServiceService: TzapiServiceService,
  ) { }

  DataVersion_Check(refreshMethod){
    let postData=new TZDataVersion_Req();
    postData.TZQuestion_Version=this.userProfileService.getQuestionTotal().Version;
    postData.UserAnswer_Version=this.userProfileService.getUserAnswerTotal().Version;
    postData.UserFav_Version=this.userProfileService.getFavTotal().Version;
    postData.UserQuestionSuggest_Version=this.userProfileService.getUserQuestionSuggest().Version;
    postData.UserProfile_Version=this.userProfileService.getUserProfile().Version;
    postData.UserProfile_MedicalHistoryForm_Version=this.userProfileService.getUserProfile_MedicalHistoryForm().Version;

    // if (!postData.TZQuestion_Version) postData.TZQuestion_Version=""
    // if (!postData.UserAnswer_Version) postData.UserAnswer_Version=""
    // if (!postData.UserFav_Version) postData.UserFav_Version=""
    // if (!postData.UserQuestionSuggest_Version) postData.UserQuestionSuggest_Version=""
    // if (!postData.UserProfile_Version) postData.UserProfile_Version=""
    // if (!postData.UserProfile_MedicalHistoryForm_Version) postData.UserProfile_MedicalHistoryForm_Version=""


    this.tzapiServiceService.DataVersion_Check(postData).subscribe((res)=>{
      if (res.data.TZQuestion_IsChange){
        this.userProfileService.load_QuestionTotal(refreshMethod);
      }
      if (res.data.UserAnswer_IsChange){
        this.userProfileService.load_UserAnswer(refreshMethod);
      }
      if (res.data.UserFav_IsChange){
        this.userProfileService.load_UserFav();
      }
      if (res.data.UserProfile_IsChange){
        this.userProfileService.load_UserProfile();
      }
      if (res.data.UserQuestionSuggest_IsChange){
        this.userProfileService.load_UserQuestionSuggest(refreshMethod);
      }
      if (res.data.UserProfile_IsChange){
        this.userProfileService.load_UserProfile();
      }
      if (res.data.UserQuestionSuggest_IsChange){
        this.userProfileService.load_UserProfile_MedicalHistoryForm();
      }
    })

  }
}
