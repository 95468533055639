import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { IonicModule } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { FormsModule } from '@angular/forms';
import {DatePipe} from '@angular/common';
import {HttpErrorInterceptor} from './providers/HttpErrorInterceptor';
import {AuthInterceptor} from './providers/AuthInterceptor';
import {  BrowserTransferStateModule } from '@angular/platform-browser';
import {InAppBrowser} from '@awesome-cordova-plugins/in-app-browser/ngx';
// import { Drivers, Storage } from '@ionic/storage';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';
import { GoogleAnalytics } from '@awesome-cordova-plugins/google-analytics/ngx';

@NgModule({
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserTransferStateModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    IonicModule.forRoot(),
    IonicStorageModule.forRoot({
      driverOrder: ["localstorage"]
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production
    })
  ],
  declarations: [AppComponent],
  providers: [InAppBrowser, SplashScreen, StatusBar,DatePipe,SocialSharing,GoogleAnalytics,
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    { provide : HTTP_INTERCEPTORS,useClass: AuthInterceptor, multi   : true,},
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
