// namespace tzsci {


  export class TZUserAnswerTotal{
    Version:string="";

    Questions:TZUserAnswerQuestion[]=[];
  }

  export class TZUserAnswerQuestion{
    relQuestion:string="";

    questionAnswerType:string="";

    //單選
    userAnswerSingle:string="";

    //多選
    userAnswerMulti:string[]=[];

    //其他文字
    userAnswerText:string="";

    //使用者選擇了"其他"
    userSelectOther:boolean=false;
  }

  export class TZUserQuestionSuggest{
    Version:string="";

    riskLevel:string="";  //A
    riskLevelText:string="請先進行風險評估"; //A(重度風險)
    riskLevelBackHospitalDays:number=7;
    riskLevelSuggest:string="";
    caseManagerSuggest:string="";

    caseManagerBackHospitalDays:number=0;
    memo:string="";

    suggestItems:TZUserQuestionSuggestItem[]=[];
  }

  export class TZUserQuestionSuggestItem{
    title="";
    content="";
    memo="";
  }



export class TZUserFavTotal{
    Version:string="";
    FavItems:TZUserFavItem[]=[];
  }

  export class TZUserFavItem{
    systemId:string="";
  }

  export class TZUserProfile{
    Version:string="";

    medicalHistoryForInHospital:string="";
    medicalHistoryForDoctor:string="";
    medicalHistoryForEnglish:string="";


    lastBackHospitalDate:Date =null;

  }

  export class TZUserProfile_MedicalHistoryForm{
    Version:string="";

    height:string="";
    weight:string="";
    years:string="";
    part:string="";
    surg:string="";
    surgOther:string="";
    sick:string="";
    sickOther:string="";
    medical:string="";
    medicalOther:string="";
    allergy:string="";
    allergyOther:string="";

    flag_smook:string="";
    flag_al:string="";
    flag_betelNut:string="";

    other:string="";
  }

  export class TZUserAppLocalInfo{
    userName:string="";
    userPhone:string="";
    userPassword:string="";
    welcomePageIsIgnore:string="";
  }

  export class TZKnowledgeTotal {
    Version:string= "";

    KBItems: TZKnowledgeItem[];
  }

  export class TZKnowledgeItem {
    SystemId: string;
    OrderKey: string;
    IsEnable: boolean;
    Group1: string;
    Tags: string[];
    Text: string;
    HtmlContent: string;
  }

  export class TZDoctorTotal {
    Version:string= "";

    Doctors:TZDoctorItem[]=[];
  }

  export class TZDoctorItem {
    SystemId: string;
    city: string;
    hname: string;
    hnames: string;
    hlevel: string;
    name: string;
    dep: string;
    tel: string;
    add: string;
    link2: string;
    link1: string;
    Uauc: string;
    bun: string;
    check1: string;
    check2: string;
    check3: string;
    check4: string;
    check5: string;
    opt1: string;
    opt2: string;
    opt3: string;
    opt4: string;
    opt5: string;
    opt6: string;
    opt7: string;
    opt8: string;
    opt9: string;
    opt10: string;
    opt11: string;
    opt12: string;
    opt13: string;
    opt14: string;
  }


  export class TZDoctorItemForSearch {
    SystemId: string ="";
    city: []=[];
    hname: []=[];
    hnames: []=[];
    hlevel: []=[];
    name: []=[];
    dep: []=[];
    tel: []=[];
    add: []=[];
    link2: []=[];
    link1: []=[];
    Uauc: []=[];
    bun: []=[];
    check1: []=[];
    check2: []=[];
    check3: []=[];
    check4: []=[];
    check5: []=[];
    opt1: []=[];
    opt2: []=[];
    opt3: []=[];
    opt4: []=[];
    opt5: []=[];
    opt6: []=[];
    opt7: []=[];
    opt8: []=[];
    opt9: []=[];
    opt10: []=[];
    opt11: []=[];
    opt12: []=[];
    opt13: []=[];
    opt14: []=[];
  }





