import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DataTempService {

  public tempData: any;
  public tempData2: any;
  // public IsShowedWelcome:boolean=false;

  constructor() { }
}
