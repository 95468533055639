import { Injectable, PLATFORM_ID, Inject } from '@angular/core'
import { isPlatformBrowser } from '@angular/common'
import {TZQuestion, TZQuestionTotal} from '../interfaces/question';
import {LocalStorageRefService} from './LocalStorageRefService';
import {
  TZDoctorTotal,
  TZKnowledgeTotal,
  TZUserAnswerQuestion,
  TZUserAnswerTotal,
  TZUserAppLocalInfo,
  TZUserFavItem,
  TZUserFavTotal,
  TZUserProfile, TZUserProfile_MedicalHistoryForm,
  TZUserQuestionSuggest
} from '../interfaces/userProfile';
import {TzapiServiceService} from './tzapi-service.service';
import {EvaluateData} from './evaluate-data';
import {HttpClient} from '@angular/common/http';
import {map, tap} from 'rxjs/operators';
import {TZResponse} from '../interfaces/other';
import {Observable} from 'rxjs';
import {TZDataVersion_Res} from '../interfaces/tzapi';
import {UtilService} from '../services/util.service';
import { constDeclare } from '../services/constDeclare';

function getLocalStorage() {
  return localStorage
}

@Injectable({ providedIn: 'root' })
export class UserProfileService {

  tzQuestionTotal:TZQuestionTotal;
  tzKnowledgeTotal:TZKnowledgeTotal;
  tzDoctorTotal:TZDoctorTotal;
  tzUserAnswerTotal:TZUserAnswerTotal;
  tzUserQuestionSuggest:TZUserQuestionSuggest;
  tzUserFavTotal:TZUserFavTotal;
  tzUserProfile:TZUserProfile;
  tzUserProfile_MedicalHistoryForm:TZUserProfile_MedicalHistoryForm;
  tzUserAppLocalInfo:TZUserAppLocalInfo;

  constructor(
    @Inject(PLATFORM_ID) private platformId,
    public localStorageRefService: LocalStorageRefService,
    public tzapiServiceService: TzapiServiceService,
    public evaluateData: EvaluateData,
    public http: HttpClient,
    private utilService: UtilService,
  ) {


    //----------------------
    try {
      //QQ20220706
      this.tzQuestionTotal = this.localStorageRefService.QuestionTotal_getData();

      if (this.tzQuestionTotal === null) {
        this.tzQuestionTotal = new TZQuestionTotal();

        //初使化
        this.http
          .get('assets/data/question.json')
          .subscribe((data: TZQuestionTotal) => {
            this.tzQuestionTotal = data;

            this.localStorageRefService.QuestionTotal_setData(this.tzQuestionTotal);
          });
      }
      // this.tzQuestionTotal = new TZQuestionTotal();
      //
      // //初使化
      // this.http
      //   .get('assets/data/question.json')
      //   .subscribe((data: TZQuestionTotal) => {
      //     this.tzQuestionTotal = data;
      //
      //     this.localStorageRefService.QuestionTotal_setData(this.tzQuestionTotal);
      //   });
    } catch (e) {
      console.log(e);
    }
    //----------------------
    try {
      this.tzKnowledgeTotal = this.localStorageRefService.QuestionTotal_getData();

      if (this.tzKnowledgeTotal === null) {
        this.tzKnowledgeTotal = new TZKnowledgeTotal();

        //初使化
        this.http
          .get('assets/data/question.json')
          .subscribe((data: TZKnowledgeTotal) => {
            this.tzKnowledgeTotal = data;

            this.localStorageRefService.QuestionTotal_setData(this.tzKnowledgeTotal);
          });
      }
    } catch (e) {
      console.log(e);
    }
    //----------------------
    try {
      this.tzDoctorTotal = this.localStorageRefService.QuestionTotal_getData();

      if (this.tzDoctorTotal === null) {
        this.tzDoctorTotal = new TZDoctorTotal();

        //初使化
        this.http
          .get('assets/data/question.json')
          .subscribe((data: TZDoctorTotal) => {
            this.tzDoctorTotal = data;

            this.localStorageRefService.QuestionTotal_setData(this.tzDoctorTotal);
          });
      }
    } catch (e) {
      console.log(e);
    }
    //----------------------
    try {
      this.tzUserAnswerTotal = this.localStorageRefService.UserAnswer_getData();

      if (this.tzUserAnswerTotal === null) {
        this.tzUserAnswerTotal = new TZUserAnswerTotal();
      }
    } catch (e) {
      console.log(e);
    }

    //----------------------
    try {
      this.tzUserQuestionSuggest = this.localStorageRefService.UserQuestionSuggest_getData();

      if (this.tzUserQuestionSuggest === null) {
        this.tzUserQuestionSuggest = new TZUserQuestionSuggest();
      }
    } catch (e) {
      console.log(e);
    }

    //----------------------
    try {
      this.tzUserFavTotal = this.localStorageRefService.UserFav_getData();

      if (this.tzUserFavTotal === null) {
        this.tzUserFavTotal = new TZUserFavTotal();
      }
    } catch (e) {
      console.log(e);
    }

    //----------------------
    try {
      this.tzUserProfile = this.localStorageRefService.UserProfile_getData();

      if (this.tzUserProfile === null) {
        this.tzUserProfile = new TZUserProfile();
      }
    } catch (e) {
      console.log(e);
    }
    //---------------------------------------
    try {
      this.tzUserProfile_MedicalHistoryForm = this.localStorageRefService.UserProfile_MedicalHistoryForm_getData();

      if (this.tzUserProfile_MedicalHistoryForm === null) {
        this.tzUserProfile_MedicalHistoryForm = new TZUserProfile_MedicalHistoryForm();
      }
    } catch (e) {
      console.log(e);
    }
    //---------------------------------------
    try {
      this.tzUserAppLocalInfo = this.localStorageRefService.UserAppLocalInfo_getData();

      if (this.tzUserAppLocalInfo === null) {
        this.tzUserAppLocalInfo = new TZUserAppLocalInfo();
      }
    } catch (e) {
      console.log(e);
    }

  }

  loadAllDataFromServer(){
    this.load_UserAnswer();
    // this.load_UserAppLocalInfo();  // 永遠以Local為準
    this.load_UserFav();
    this.load_UserProfile();
    this.load_UserQuestionSuggest();
  }
  removeAllDataFromLocal(){
    this.localStorageRefService.UserAnswer_removeData();
    // this.localStorageRefService.UserAppLocalInfo_removeData(); // 永遠以Local為準
    this.localStorageRefService.UserFav_removeData();
    this.localStorageRefService.UserProfile_removeData();
    this.localStorageRefService.UserQuestionSuggest_removeData();
  }

  //---------------------------------------
  getQuestionTotal():TZQuestionTotal{
    return this.tzQuestionTotal;
  }

  load_QuestionTotal(refreshMethod?){
    return this.tzapiServiceService.QuestionTotal_Get().subscribe((res)=>{
      if (res.data){
        this.tzQuestionTotal=res.data;

        this.localStorageRefService.QuestionTotal_setData(this.tzQuestionTotal);
        console.log("load_QuestionTotal");
        if (refreshMethod){
          refreshMethod();
        }
      }
    });
  }

  //以Server為準，不需要此method
  // saveToStorage_QuestionTotal(){
  //   this.localStorageRefService.QuestionTotal_setData(this.tzQuestionTotal);
  //
  //   this.tzapiServiceService.QuestionTotal_Save(this.tzQuestionTotal);
  // }
  //---------------------------------------
  getKnowledgeTotal():TZKnowledgeTotal{
    return this.tzKnowledgeTotal;
  }

  load_KnowledgeTotal(){
    this.tzapiServiceService.KnowledgeTotal_Get().subscribe((res)=>{
      if (res.data){
        this.tzKnowledgeTotal=res.data;

        this.localStorageRefService.KnowledgeTotal_setData(this.tzKnowledgeTotal);
      }
    });
  }

  //以Server為準，不需要此method
  // saveToStorage_KnowledgeTotal(){
  //   this.localStorageRefService.KnowledgeTotal_setData(this.tzKnowledgeTotal);
  //
  //   this.tzapiServiceService.KnowledgeTotal_Save(this.tzKnowledgeTotal);
  // }
  //---------------------------------------
  getDoctorTotal():TZDoctorTotal{
    return this.tzDoctorTotal;
  }

  load_DoctorTotal(){
    this.tzapiServiceService.DoctorTotal_Get().subscribe((res)=>{
      if (res.data){
        this.tzDoctorTotal=res.data;

        this.localStorageRefService.DoctorTotal_setData(this.tzDoctorTotal);
      }
    });
  }

  //以Server為準，不需要此method
  // saveToStorage_DoctorTotal(){
  //   this.localStorageRefService.DoctorTotal_setData(this.tzDoctorTotal);
  //
  //   this.tzapiServiceService.DoctorTotal_Save(this.tzDoctorTotal);
  // }
  //---------------------------------------
  getUserAnswerTotal():TZUserAnswerTotal{
    return this.tzUserAnswerTotal;
  }

  saveToStorage_UserAnswer_nextStep(){
    this.localStorageRefService.UserAnswer_setData(this.tzUserAnswerTotal);

    // this.tzapiServiceService.UserAnswer_Save(this.tzUserAnswerTotal);
  }
  saveToStorage_UserAnswer(){
    this.localStorageRefService.UserAnswer_setData(this.tzUserAnswerTotal);

    this.tzapiServiceService.UserAnswer_Save(this.tzUserAnswerTotal).subscribe((res:TZResponse<TZUserQuestionSuggest>)=>{
      this.tzUserQuestionSuggest=res.data;
      this.saveToStorage_UserQuestionSuggest();
    })
  }


  load_UserAnswer(refreshMethod?){
    return this.tzapiServiceService.UserAnswer_Get().subscribe((res)=>{
      if (res.data){
        this.tzUserAnswerTotal=res.data;

        this.localStorageRefService.UserAnswer_setData(this.tzUserAnswerTotal);

        console.log("load_UserAnswer");
        if (refreshMethod){
          refreshMethod();
        }
      }
    });
  }

  newJsonParse<T>(obj:T,json) {
    let init=JSON.parse(json);

    Object.assign(obj, init);
  }

  //---------------------------------------
  getUserQuestionSuggest():TZUserQuestionSuggest{
    return this.tzUserQuestionSuggest;
  }

  load_UserQuestionSuggest(refreshMethod?){
    return this.tzapiServiceService.UserQuestionSuggest_Get().subscribe((res)=>{
      if (res.data){
        this.tzUserQuestionSuggest=res.data;

        this.localStorageRefService.UserQuestionSuggest_setData(this.tzUserQuestionSuggest);

        if (refreshMethod){
          refreshMethod();
        }
      }
    });
  }


  saveToStorage_UserQuestionSuggest(){
    this.localStorageRefService.UserQuestionSuggest_setData(this.tzUserQuestionSuggest);

    //沒有Save的需要
    // this.tzapiServiceService.UserQuestionSuggest_Save(this.tzUserQuestionSuggest);
  }

  //---------------------------------------
  addRemoveFav(systemId){
    let findIndex = this.tzUserFavTotal.FavItems.findIndex(s=>s.systemId===systemId);

    if (findIndex>=0){
      this.tzUserFavTotal.FavItems.splice(findIndex, 1);
    }else{
      let item=new TZUserFavItem();

      item.systemId=systemId;

      this.tzUserFavTotal.FavItems.push(item);

      this.saveToStorage_UserFav();
    }

  }

  isFav(systemId){
    let tzUserFavItems = this.tzUserFavTotal.FavItems.filter(s=>s.systemId===systemId);

    if (tzUserFavItems.length>0){
      return true;
    }else{
      return false;
    }
  }
  getFavTotal():TZUserFavTotal{
    return this.tzUserFavTotal;
  }

  getFavItems():string[]{
    return this.tzUserFavTotal.FavItems.map(s=>s.systemId);
  }

  load_UserFav(){
    return this.tzapiServiceService.UserFav_Get().subscribe((res)=>{
      if (res.data){
        this.tzUserFavTotal=res.data;

        this.localStorageRefService.UserFav_setData(this.tzUserFavTotal);
      }
    });
  }

  saveToStorage_UserFav(){
    this.localStorageRefService.UserFav_setData(this.tzUserFavTotal);

    this.tzapiServiceService.UserFav_Save(this.tzUserFavTotal);
  }
  //---------------------------------------
  getUserProfile():TZUserProfile{
    return this.tzUserProfile;
  }

  load_UserProfile(){
    return this.tzapiServiceService.UserProfile_Get().subscribe((res)=>{
      if (res.data){
        this.tzUserProfile=res.data;

        this.localStorageRefService.UserProfile_setData(this.tzUserProfile);
      }
    });
  }

  async saveToStorage_UserProfile(msg){
    this.localStorageRefService.UserProfile_setData(this.tzUserProfile);
    this.tzapiServiceService.UserProfile_Save(this.tzUserProfile).subscribe((res)=>{
      this.utilService.showAlert_OK(msg)
    });
  }
  //---------------------------------------
  getUserProfile_MedicalHistoryForm():TZUserProfile_MedicalHistoryForm{
    return this.tzUserProfile_MedicalHistoryForm;
  }

  load_UserProfile_MedicalHistoryForm(){
    return this.tzapiServiceService.UserProfile_MedicalHistoryForm_Get().subscribe((res)=>{
      if (res.data){
        this.tzUserProfile_MedicalHistoryForm=res.data;

        this.localStorageRefService.UserProfile_MedicalHistoryForm_setData(this.tzUserProfile_MedicalHistoryForm);
      }
    });
  }

  async saveToStorage_UserProfile_MedicalHistoryForm(msg){
    this.localStorageRefService.UserProfile_MedicalHistoryForm_setData(this.tzUserProfile_MedicalHistoryForm);
    this.tzapiServiceService.UserProfile_MedicalHistoryForm_Save(this.tzUserProfile_MedicalHistoryForm).subscribe((res)=>{
      this.utilService.showAlert_OK(msg)
    });
  }
  //---------------------------------------

  public IsLogin_asGuest():boolean{
    if (this.getUserAppLocalInfo().userPhone==constDeclare.GuestUserId){
      return true;
    }else{
      return false;
    }
  }

  getUserAppLocalInfo():TZUserAppLocalInfo{
    return this.tzUserAppLocalInfo;
  }

  //此Method應該是不需要，因為總是以local為準
  load_UserAppLocalInfo(){
    this.tzapiServiceService.UserAppLocalInfo_Get().subscribe((res)=>{
      if (res.data){
        this.tzUserAppLocalInfo=res.data;

        this.localStorageRefService.UserAppLocalInfo_setData(this.tzUserAppLocalInfo);
      }
    });
  }

  saveToStorage_UserAppLocalInfo(){
    this.localStorageRefService.UserAppLocalInfo_setData(this.tzUserAppLocalInfo);

    this.tzapiServiceService.UserAppLocalInfo_Save(this.tzUserAppLocalInfo);
  }
}
