import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {LocalStorageRefService} from './LocalStorageRefService';



@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    public localStorageRefService:LocalStorageRefService
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    req = req.clone({
      setHeaders: {
        'Content-Type' : 'application/json; charset=utf-8',
        'Accept'       : 'application/json',
        'Authorization': `Bearer ${this.localStorageRefService.AccessToken_getData()}`,
      },
    });

    return next.handle(req);
  }
}
