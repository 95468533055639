export class constDeclare {
  public static GuestUserId='guest';
  public static GuestUserPwd='1234';

  public static IsDebuging:boolean=false;





}
