import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CheckTutorial } from './providers/check-tutorial.service';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/app/tabs/home',
    pathMatch: 'full'
  },
  {
    path: 'account',
    loadChildren: () => import('./pages/account/account.module').then(m => m.AccountModule)
  },
  {
    path: 'support',
    loadChildren: () => import('./pages/support/support.module').then(m => m.SupportModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'signup',
    loadChildren: () => import('./pages/signup/signup.module').then(m => m.SignUpModule)
  },
  {
    path: 'app',
    loadChildren: () => import('./pages/tabs-page/tabs-page.module').then(m => m.TabsModule)
  },
  {
    path: 'tutorial',
    loadChildren: () => import('./pages/tutorial/tutorial.module').then(m => m.TutorialModule),
    canLoad: [CheckTutorial]
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'doctors',
    loadChildren: () => import('./pages/doctors/doctors.module').then( m => m.DoctorsPageModule)
  },
  {
    path: 'about-us',
    loadChildren: () => import('./pages/about-us/about-us.module').then( m => m.AboutUsPageModule)
  },
  {
    path: 'knowbase',
    loadChildren: () => import('./pages/knowbase/knowbase.module').then( m => m.KnowbasePageModule)
  },
  {
    path: 'question-group',
    loadChildren: () => import('./pages/question-group/question-group.module').then( m => m.QuestionGroupPageModule)
  },
  {
    path: 'question-list/:SystemId',
    loadChildren: () => import('./pages/question-list/question-list.module').then( m => m.QuestionListPageModule)
  },
  {
    path: 'answer',
    loadChildren: () => import('./pages/answer/answer.module').then( m => m.AnswerPageModule)
  },
  {
    path: 'article',
    loadChildren: () => import('./pages/article/article.module').then( m => m.ArticlePageModule)
  },
  {
    path: 'doctors-tagcloud',
    loadChildren: () => import('./pages/doctors-tagcloud/doctors-tagcloud.module').then( m => m.DoctorsTagcloudPageModule)
  },
  {
    path: 'doctors-filter',
    loadChildren: () => import('./pages/doctors-filter/doctors-filter.module').then( m => m.DoctorsFilterPageModule)
  },
  {
    path: 'knowbase-tagcloud',
    loadChildren: () => import('./pages/knowbase-tagcloud/knowbase-tagcloud.module').then( m => m.KnowbaseTagcloudPageModule)
  },
  {
    path: 'doctors-detail',
    loadChildren: () => import('./pages/doctors-detail/doctors-detail.module').then( m => m.DoctorsDetailPageModule)
  },
  {
    path: 'my',
    loadChildren: () => import('./pages/my/my.module').then( m => m.MyPageModule)
  },
  {
    path: 'must-know',
    loadChildren: () => import('./pages/must-know/must-know.module').then( m => m.MustKnowPageModule)
  },
  {
    path: 'home-risk',
    loadChildren: () => import('./pages/home-risk/home-risk.module').then( m => m.HomeRiskPageModule)
  },
  {
    path: 'test',
    loadChildren: () => import('./pages/test/test.module').then( m => m.TestPageModule)
  },  {
    path: 'medical-history',
    loadChildren: () => import('./pages/medical-history/medical-history.module').then( m => m.MedicalHistoryPageModule)
  },
  {
    path: 'medical-history-feedback',
    loadChildren: () => import('./pages/medical-history-feedback/medical-history-feedback.module').then( m => m.MedicalHistoryFeedbackPageModule)
  },
  {
    path: 'article-feedback',
    loadChildren: () => import('./pages/article-feedback/article-feedback.module').then( m => m.ArticleFeedbackPageModule)
  },
  {
    path: 'must-know-feedback',
    loadChildren: () => import('./pages/must-know-feedback/must-know-feedback.module').then( m => m.MustKnowFeedbackPageModule)
  },
  {
    path: 'article-popup',
    loadChildren: () => import('./pages/article-popup/article-popup.module').then( m => m.ArticlePopupPageModule)
  },
  {
    path: 'home-back-day',
    loadChildren: () => import('./pages/home-back-day/home-back-day.module').then( m => m.HomeBackDayPageModule)
  },
  {
    path: 'login-fill-form',
    loadChildren: () => import('./pages/login-fill-form/login-fill-form.module').then( m => m.LoginFillFormPageModule)
  },
  {
    path: 'test2',
    loadChildren: () => import('./pages/test2/test2.module').then( m => m.Test2PageModule)
  },
  {
    path: 'login-forgot-pwd',
    loadChildren: () => import('./pages/login-forgot-pwd/login-forgot-pwd.module').then( m => m.LoginForgotPwdPageModule)
  },
  {
    path: 'login-change-pwd',
    loadChildren: () => import('./pages/login-change-pwd/login-change-pwd.module').then( m => m.LoginChangePwdPageModule)
  },
  {
    path: 'test-demo-page1',
    loadChildren: () => import('./pages/test-demo-page1/test-demo-page1.module').then( m => m.TestDemoPage1PageModule)
  },
  {
    path: 'test-demo-page2',
    loadChildren: () => import('./pages/test-demo-page2/test-demo-page2.module').then( m => m.TestDemoPage2PageModule)
  },
  {
    path: 'test-demo-user1',
    loadChildren: () => import('./pages/test-demo-user1/test-demo-user1.module').then( m => m.TestDemoUser1PageModule)
  },
  {
    path: 'test-demo-user2',
    loadChildren: () => import('./pages/test-demo-user2/test-demo-user2.module').then( m => m.TestDemoUser2PageModule)
  },
  {
    path: 'system',
    loadChildren: () => import('./pages/system/system.module').then( m => m.SystemPageModule)
  },
  {
    path: 'gen-question-text-page',
    loadChildren: () => import('./pages/gen-question-text-page/gen-question-text-page.module').then( m => m.GenQuestionTextPagePageModule)
  },
  {
    path: 'welcome',
    loadChildren: () => import('./pages/welcome/welcome.module').then( m => m.WelcomePageModule)
  }


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
