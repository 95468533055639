import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import {AnswerItem, UserAnswer} from '../interfaces/userAnswer';
import {TZQuestion} from '../interfaces/question';


@Injectable({
  providedIn: 'root'
})
export class UserData {
  favorites: string[] = [];
  HAS_LOGGED_IN = 'hasLoggedIn';
  HAS_SEEN_TUTORIAL = 'hasSeenTutorial';
  USER_ANSWER = 'userAnswer';
  QUESTIONS = 'questions';

  constructor(
    public storage: Storage
  ) { }

  hasFavorite(sessionName: string): boolean {
    return (this.favorites.indexOf(sessionName) > -1);
  }

  addFavorite(sessionName: string): void {
    this.favorites.push(sessionName);
  }

  removeFavorite(sessionName: string): void {
    const index = this.favorites.indexOf(sessionName);
    if (index > -1) {
      this.favorites.splice(index, 1);
    }
  }

  login(username: string): Promise<any> {
    return this.storage.set(this.HAS_LOGGED_IN, true).then(() => {
      this.setUsername(username);
      return window.dispatchEvent(new CustomEvent('user:login'));
    });
  }

  signup(username: string): Promise<any> {
    return this.storage.set(this.HAS_LOGGED_IN, true).then(() => {
      this.setUsername(username);
      return window.dispatchEvent(new CustomEvent('user:signup'));
    });
  }

  logout(): Promise<any> {
    return this.storage.remove(this.HAS_LOGGED_IN).then(() => {
      return this.storage.remove('username');
    }).then(() => {
      window.dispatchEvent(new CustomEvent('user:logout'));
    });
  }

  setUsername(username: string): Promise<any> {
    return this.storage.set('username', username);
  }

  getUsername(): Promise<string> {
    return this.storage.get('username').then((value) => {
      return value;
    });
  }

  isLoggedIn(): Promise<boolean> {
    return this.storage.get(this.HAS_LOGGED_IN).then((value) => {
      return value === true;
    });
  }

  checkHasSeenTutorial(): Promise<string> {
    return this.storage.get(this.HAS_SEEN_TUTORIAL).then((value) => {
      return value;
    });
  }

  saveAnswers(answers: UserAnswer): Promise<any> {
    return this.storage.set(this.USER_ANSWER, answers).then(() => {
    });
  }


  getAnswers(): UserAnswer {
    this.storage.get(this.USER_ANSWER).then((value) => {
      return value;
    });

    return  { AnswerItems: []};
  }

  saveQuestion(questions: TZQuestion[]): Promise<any> {
    // return this.storage.set(this.QUESTIONS, questions).then(() => {
    return this.storage.set(this.QUESTIONS, questions).then(() => {
    });
  }

  getQuestion(): Promise<TZQuestion[]> {
    return this.storage.get(this.QUESTIONS).then((value) => {
      return value;
    });

  }



}
