import { Injectable } from '@angular/core';
import {AlertController, Platform, ToastController} from '@ionic/angular';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  constructor(
    public toastCtrl: ToastController,
    public alertController: AlertController,
    public platform: Platform,
    public socialSharing: SocialSharing
  ) { }

  shareText(msg){
    this.socialSharing.share(msg).then(() => {

    }).catch((e) => {
      this.showToast_Alert("抱歉無法分享，請改用複製文字的方式")
    });
  }

  isWeb_MyCode(){
    if(!this.isAndroid() && !this.isiOS())
    {
      return true
    }else{
      return false;
    }
  }
  isAndroid(){
    return this.platform.is("android");
  }

  //如果是chrome也會是true
  isiOS(){
    return this.platform.is("ios");
  }

  //如果是android也會是true
  isDesktop(){
    return this.platform.is("desktop");
  }

  isMobileWeb(){
    return this.platform.is("mobileweb");
  }

  async showToast_Msg(msg) {
    const toast = await this.toastCtrl.create({
      header: '訊息',
      message: msg,
      icon: 'information-circle',
      position: 'bottom',
      color:'success',
      duration: 3000
    });
    await toast.present();

    const { role } = await toast.onDidDismiss();
  }
  async showToast_Alert(msg) {
    const toast = await this.toastCtrl.create({
      header: '訊息',
      message: msg,
      icon: 'information-circle',
      position: 'bottom',
      color:'danger',
      duration: 3000
    });
    await toast.present();

    const { role } = await toast.onDidDismiss();
  }

  async showAlert_OK(msg, buttonOKText='好的'){
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: '訊息',
      subHeader: '',
      message: msg,
      buttons: [buttonOKText]
    });

    await alert.present();

    const { role } = await alert.onDidDismiss();
    console.log('onDidDismiss resolved with role', role);
  }

  //let bReturn=await this.utilService.showAlert_YesOrNo("已送出，請個管師協助編輯  編輯完成後，會以推播訊息通知您")
  async showAlert_YesOrNo(msg, buttonYesText='確定', buttonNoText="取消"){
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: '確認!',
      // message: 'Message <strong>text</strong>!!!',
      message: msg,
      buttons: [
        {
          text: buttonNoText,
          role: 'no',
          cssClass: 'secondary',
          id: 'cancel-button',
          handler: (blah) => {
            // debugger // eslint-disable-line
            // console.log('Confirm Cancel: blah');
          }
        }, {
          text: buttonYesText,
          role: 'yes',
          id: 'confirm-button',
          handler: () => {
            // debugger // eslint-disable-line
            // console.log('Confirm Okay');
          }
        }
      ]
    });


    await alert.present();

    const { role } = await alert.onDidDismiss();

    if (role==="yes"){
      return true;
    }else{
      return false;
    }
  }

  //let bReturn=await this.utilService.showAlert_Radio("已送出，請個管師協助編輯  編輯完成後，會以推播訊息通知您")
  async showAlert_Radio(msg, buttonYesText='確定', buttonNoText="取消"){
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Radio',
      inputs: [
        {
          name: 'radio1',
          type: 'radio',
          label: 'Radio 1',
          value: 'value1',
          handler: () => {
            console.log('Radio 1 selected');
          },
          checked: true
        },
        {
          name: 'radio2',
          type: 'radio',
          label: 'Radio 2',
          value: 'value2',
          handler: () => {
            console.log('Radio 2 selected');
          }
        },
        {
          name: 'radio3',
          type: 'radio',
          label: 'Radio 3',
          value: 'value3',
          handler: () => {
            console.log('Radio 3 selected');
          }
        },
        {
          name: 'radio4',
          type: 'radio',
          label: 'Radio 4',
          value: 'value4',
          handler: () => {
            console.log('Radio 4 selected');
          }
        },
        {
          name: 'radio5',
          type: 'radio',
          label: 'Radio 5',
          value: 'value5',
          handler: () => {
            console.log('Radio 5 selected');
          }
        },
        {
          name: 'radio6',
          type: 'radio',
          label: 'Radio 6 Radio 6 Radio 6 Radio 6 Radio 6 Radio 6 Radio 6 Radio 6 Radio 6 Radio 6 ',
          value: 'value6',
          handler: () => {
            console.log('Radio 6 selected');
          }
        }
      ],
      buttons: [
        {
          text: buttonNoText,
          role: 'no',
          cssClass: 'secondary',
          handler: () => {
            console.log('Confirm Cancel');
          }
        }, {
          text: buttonYesText,
          role: 'yes',
          handler: () => {
            console.log('Confirm Ok');
          }
        }
      ]
    });

    await alert.present();

    const { role } = await alert.onDidDismiss();

    if (role==="yes"){
      return true;
    }else{
      return false;
    }
  }

  //let bReturn=await this.utilService.showAlert_Checkbox("已送出，請個管師協助編輯  編輯完成後，會以推播訊息通知您")
  async showAlert_Checkbox(msg, buttonYesText='確定', buttonNoText="取消"){
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Checkbox',
      inputs: [
        {
          name: 'checkbox1',
          type: 'checkbox',
          label: 'Checkbox 1',
          value: 'value1',
          handler: () => {
            console.log('Checkbox 1 selected');
          },
          checked: true
        },

        {
          name: 'checkbox2',
          type: 'checkbox',
          label: 'Checkbox 2',
          value: 'value2',
          handler: () => {
            console.log('Checkbox 2 selected');
          }
        },

        {
          name: 'checkbox3',
          type: 'checkbox',
          label: 'Checkbox 3',
          value: 'value3',
          handler: () => {
            console.log('Checkbox 3 selected');
          }
        },

        {
          name: 'checkbox4',
          type: 'checkbox',
          label: 'Checkbox 4',
          value: 'value4',
          handler: () => {
            console.log('Checkbox 4 selected');
          }
        },

        {
          name: 'checkbox5',
          type: 'checkbox',
          label: 'Checkbox 5',
          value: 'value5',
          handler: () => {
            console.log('Checkbox 5 selected');
          }
        },

        {
          name: 'checkbox6',
          type: 'checkbox',
          label: 'Checkbox 6 Checkbox 6 Checkbox 6 Checkbox 6 Checkbox 6 Checkbox 6 Checkbox 6 Checkbox 6 Checkbox 6 Checkbox 6',
          value: 'value6',
          handler: () => {
            console.log('Checkbox 6 selected');
          }
        }
      ],
      buttons: [
        {
          text: buttonNoText,
          role: 'no',
          cssClass: 'secondary',
          handler: () => {
            console.log('Confirm Cancel');
          }
        }, {
          text: buttonYesText,
          role: 'yes',
          handler: () => {
            console.log('Confirm Ok');
          }
        }
      ]
    });


    await alert.present();

    const { role } = await alert.onDidDismiss();

    if (role==="yes"){
      return true;
    }else{
      return false;
    }
  }

  //let bReturn=await this.utilService.showAlert_FormDemo("已送出，請個管師協助編輯  編輯完成後，會以推播訊息通知您")
  //好像只是用來顯示，不知如何取得輸入值
  async showAlert_FormDemo(msg, buttonYesText='確定', buttonNoText="取消"){
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Prompt!',
      inputs: [
        {
          name: 'name1',
          type: 'text',
          placeholder: 'Placeholder 1'
        },
        {
          name: 'name2',
          type: 'text',
          id: 'name2-id',
          value: 'hello',
          placeholder: 'Placeholder 2'
        },
        // multiline input.
        {
          name: 'paragraph',
          id: 'paragraph',
          type: 'textarea',
          placeholder: 'Placeholder 3'
        },
        {
          name: 'name3',
          value: 'http://ionicframework.com',
          type: 'url',
          placeholder: 'Favorite site ever'
        },
        // input date with min & max
        {
          name: 'name4',
          type: 'date',
          min: '2017-03-01',
          max: '2018-01-12'
        },
        // input date without min nor max
        {
          name: 'name5',
          type: 'date'
        },
        {
          name: 'name6',
          type: 'number',
          min: -5,
          max: 10
        },
        {
          name: 'name7',
          type: 'number'
        },
        {
          name: 'name8',
          type: 'password',
          placeholder: 'Advanced Attributes',
          cssClass: 'specialClass',
          attributes: {
            maxlength: 4,
            inputmode: 'decimal'
          }
        }
      ],
      buttons: [
        {
          text: buttonNoText,
          role: 'no',
          cssClass: 'secondary',
          handler: () => {
            console.log('Confirm Cancel');
          }
        }, {
          text: buttonYesText,
          role: 'yes',
          handler: (data) => {
            alert.dismiss(data)
            return false
          },
        }
      ]
    });

    await alert.present();

    const { role } = await alert.onDidDismiss();

    if (role==="yes"){
      return true;
    }else{
      return false;
    }

    // let choice
    // await alert.present();
    // await alert.onDidDismiss().then((data) => {
    //   choice=data;
    // })
    // return choice;
  }

}
