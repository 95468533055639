// namespace tzsci {

  export class TZQuestionTotal{
    Version:string="";

    Questions:TZQuestion[]=[];
  }

  export class TZQuestion{
    systemId:string="";
    seq:number=0;
    group1:string="";
    group2:string="";
    question:string="";
    questionDesc:string="";
    answerType:string="";

    Answers:TZAnswer[]=[];

    //使用者填寫的值
    isUserAnswered:boolean=false;
    userAnswer:string="";
    userAnswerText:string="";
  }

  // export class TZQuestionEx{
  //   //使用者填寫的值
  //   isUserAnswered:boolean=false;
  //   userAnswer:string="";
  //   userAnswerText:string="";
  // }

  export class TZAnswer{
    systemId:string="";
    relQuestion:string="";
    seq:number=0;
    answer:string="";
    answerDesc:string="";
    score:string="";
    tags:string="";
    actionForPatient:string="";
    actionForCaseMag:string="";
    actionForMemo:string="";

    //使用者填寫的值
    userSelected:boolean=false;
  }

  // export class TZAnswerEx {
  //
  // }


