import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {from, Observable, of} from 'rxjs';
import {groupBy, map, mergeMap, toArray, filter, tap} from 'rxjs/operators';

import { UserData } from './user-data';
import {TZQuestion, TZQuestionTotal} from '../interfaces/question';
import {AnswerItem, UserAnswer} from '../interfaces/userAnswer';

@Injectable({
  providedIn: 'root'
})
export class EvaluateData {
  // questions: TZQuestion[];
  // userAnswer: UserAnswer;

  private cache_questions$: Observable<TZQuestionTotal>;

  constructor(
    public http: HttpClient,
    public user: UserData
  ) {}

  // load(): any {
  //   if (this.data) {
  //     return of(this.data);
  //   } else {
  //     return this.http
  //       .get('assets/data/question.json')
  //       .pipe(map(this.processData, this));
  //   }
  // }

  // processData(data: any) {
  //   // just some good 'ol JS fun with objects and arrays
  //   // build up the data by linking speakers to sessions
  //   this.data = data;
  //
  //   // // loop through each day in the schedule
  //   // this.data.forEach((day: any) => {
  //   //   // loop through each timeline group in the day
  //   //   day.groups.forEach((group: any) => {
  //   //     // loop through each session in the timeline group
  //   //     group.sessions.forEach((session: any) => {
  //   //       session.speakers = [];
  //   //       if (session.speakerNames) {
  //   //         session.speakerNames.forEach((speakerName: any) => {
  //   //           const speaker = this.data.speakers.find(
  //   //             (s: any) => s.name === speakerName
  //   //           );
  //   //           if (speaker) {
  //   //             session.speakers.push(speaker);
  //   //             speaker.sessions = speaker.sessions || [];
  //   //             speaker.sessions.push(session);
  //   //           }
  //   //         });
  //   //       }
  //   //     });
  //   //   });
  //   // });
  //
  //   return this.data;
  // }


  loadQuestions(): Observable<TZQuestionTotal> {
    // //Cache an observable
    // let requestObservable = this.http.get("http://example.com/path/to/api").map(res => res.json())
    //
    // this.cache.observable('my-cache-key', requestObservable, 300).subscribe(result => {
    //   //Use result
    //   console.log(result)
    // });
    //

    // return of([]);

    // return this.http
    //   .get('assets/data/question.json')
    //   .pipe(map(this.processData2, this));


    if(!this.cache_questions$) {
      this.cache_questions$ = this.http
            .get('assets/data/question.json')
            .pipe(map(this.processData2, this))
    }
    return this.cache_questions$;


    // this.user.getQuestion().then((data)=>{
    //   this.questions=data;
    //   if (this.questions !==null) {
    //     debugger // eslint-disable-line
    //     return of(this.questions);
    //   }else{
    //     debugger // eslint-disable-line
    //     return this.http
    //       .get('assets/data/question.json')
    //       .pipe(map(this.processData2, this))
    //
    //       // .subscribe((datas: TZQuestion[]) => {
    //       //   this.user.saveQuestion(datas);
    //       //   return of (datas);
    //       // })
    //   }
    // })


  }

  // getQuestionBySystemId(systemId){
  //   return this.http
  //     .get('assets/data/question.json')
  //     .pipe(map(this.processData2, this))
  //     .pipe(map(s=>s.filter(q=>q.systemId===systemId)));
  // }

  // getQuestionBySystemIdWithNextQuestion(systemId){
  //   return this.http
  //     .get('assets/data/question.json')
  //     .pipe(map(this.processData2, this))
  //     .pipe(map(s=>{
  //       const currentIndex=s.Questions.findIndex(q=>q.systemId===systemId);
  //       if (currentIndex<s.Questions.length-1){
  //         return [s[currentIndex],s[currentIndex+1]]
  //       }else{
  //         return [s[currentIndex],null]
  //       }
  //     }));
  // }

  processData2(data: any) {
    // just some good 'ol JS fun with objects and arrays
    // build up the data by linking speakers to sessions
    const dataOrg: TZQuestionTotal = data;


    // loop through each day in the schedule
    // dataOrg.forEach((question: TZQuestion) => {
    //   // loop through each timeline group in the question
    //   let answerItems: AnswerItem[];
    //   answerItems = this.userAnswer.AnswerItems.filter(s => s.QuestionSystemId === question.systemId);
    //
    //   if (answerItems.length > 0){
    //     question.isUserAnswered = true;
    //   }else{
    //     question.isUserAnswered = false;
    //   }
    //
    //   question.Answers.forEach((answer: any) => {
    //     // loop through each session in the timeline group
    //     let answerItem2: AnswerItem[];
    //     answerItem2 = answerItems.filter(s => s.AnswerSystemId === answer.SystemId);
    //
    //     if (answerItem2.length > 0) {
    //       answer.IsAnswered = true;
    //       answer.AnswerText1 = answerItem2[0].AnswerText1;
    //       answer.AnswerText2 = answerItem2[0].AnswerText2;
    //     }
    //
    //   });
    // });

    return dataOrg;
  }
}
