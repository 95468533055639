// namespace tzsci {


  export class TZApiLogin{
    access_token:"";
    expires_in:"";
    roles:"";
    token_type:"";
  }
  export class TZApiUserInfo{
    name:"";
    email:"";
    phone:"";
    loginProvider:"";
  }


  export class TZDataVersion_Req{
    TZQuestion_Version="";  //問題
    UserAnswer_Version="";  //回答
    UserQuestionSuggest_Version=""; //評信後的建議
    // UserAppLocalInfo_Version="";  //永遠以Local為準 (id/name..)
    UserFav_Version="";    //我的最愛
    UserProfile_Version="";   //最後回診日
    UserProfile_MedicalHistoryForm_Version="";  //我的簡歷

  }
  export class TZDataVersion_Res{
    TZQuestion_IsChange:false;
    UserAnswer_IsChange:false;
    UserQuestionSuggest_IsChange:false;
    // UserAppLocalInfo_IsChange:false;
    UserFav_IsChange:false;
    UserProfile_IsChange:false;
    UserProfile_MedicalHistoryForm_IsChange:false;
  }
