import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';

import { MenuController, Platform, ToastController } from '@ionic/angular';

import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { Storage } from '@ionic/storage';

import { UserData } from './providers/user-data';
import { GoogleAnalytics } from '@awesome-cordova-plugins/google-analytics/ngx';
import {DataVersionCheckService} from './providers/data-version-check.service';
import {LocalStorageRefService} from './providers/LocalStorageRefService';
import {UserProfileService} from './providers/user-profile.service';
import {constDeclare} from './services/constDeclare';
import {DataTempService} from './services/data-temp.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit {
  appPages = [
    {
      title: 'Schedule',
      url: '/app/tabs/schedule',
      icon: 'calendar'
    },
    {
      title: 'Speakers',
      url: '/app/tabs/speakers',
      icon: 'people'
    },
    {
      title: 'Map',
      url: '/app/tabs/map',
      icon: 'map'
    },
    {
      title: 'About',
      url: '/app/tabs/about',
      icon: 'information-circle'
    }
  ];
  loggedIn = false;
  dark = false;
  static isShowedWelcome :boolean=false;

  constructor(
    private menu: MenuController,
    private platform: Platform,
    private router: Router,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private storage: Storage,
    private userData: UserData,
    private swUpdate: SwUpdate,
    private toastCtrl: ToastController,
    private ga: GoogleAnalytics,
    public dataVersionCheckService: DataVersionCheckService,
    public localStorageRefService: LocalStorageRefService,
    public userProfileService: UserProfileService,
    public dataTempService: DataTempService,
    private _location: Location,
  ) {
    this.initializeApp();
  }

  async ngOnInit() {
    this.checkLoginStatus();
    this.listenForLoginEvents();

    // this.swUpdate.available.subscribe(async res => {
    //   const toast = await this.toastCtrl.create({
    //     message: 'Update available!',
    //     position: 'bottom',
    //     buttons: [
    //       {
    //         role: 'cancel',
    //         text: 'Reload'
    //       }
    //     ]
    //   });
    //
    //   await toast.present();
    //
    //   toast
    //     .onDidDismiss()
    //     .then(() => this.swUpdate.activateUpdate())
    //     .then(() => window.location.reload());
    // });
  }

  initializeApp() {

    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      this.router.navigateByUrl('/welcome');
    });

    if (this.localStorageRefService.IsLogined()) {
      //check version
      this.dataVersionCheckService.DataVersion_Check(this.gotoHome);
    }

    this.ga.startTrackerWithId('G-K675V4MLGS')
      .then(() => {
        console.log('Google analytics is ready now');
        this.ga.trackView('test');
        // Tracker is ready
        // You can now track pages or set additional information such as AppVersion or UserId
      })
      .catch(e => console.log('Error starting GoogleAnalytics', e));


    this.platform.backButton.subscribeWithPriority(10, (processNextHandler) => {
      // console.log('Back press handler!');
      // if (this._location.isCurrentPathEqualTo('/home')) {
      //
      //   // Show Exit Alert!
      //   console.log('Show Exit Alert!');
      //   this.showExitConfirm();
      //   processNextHandler();
      // } else {
      //
      //   // Navigate to back page
      //   console.log('Navigate to back page');
      //   this._location.back();
      //
      // }
      this._location.back();
    });

    // if (this.userProfileService.getUserAppLocalInfo().welcomePageIsIgnore !=="1"){
    //   // debugger // eslint-disable-line
    //   if (!AppComponent.isShowedWelcome){
    //     AppComponent.isShowedWelcome=true;
    //     this.router.navigateByUrl('/welcome');
    //   }
    //
    //   this.router.navigateByUrl('/welcome');
    // }

    //一律顯示
    // if (!AppComponent.isShowedWelcome){
    //   AppComponent.isShowedWelcome=true;
    //   this.router.navigateByUrl('/welcome');
    // }
  }
  gotoHome(){
    // this.router.navigateByUrl('/app/tabs/home');
    window.location.assign('/app/tabs/home');
  }
  checkLoginStatus() {
    return this.userData.isLoggedIn().then(loggedIn => {
      return this.updateLoggedInStatus(loggedIn);
    });
  }

  updateLoggedInStatus(loggedIn: boolean) {
    setTimeout(() => {
      this.loggedIn = loggedIn;
    }, 300);
  }

  listenForLoginEvents() {
    window.addEventListener('user:login', () => {
      this.updateLoggedInStatus(true);
    });

    window.addEventListener('user:signup', () => {
      this.updateLoggedInStatus(true);
    });

    window.addEventListener('user:logout', () => {
      this.updateLoggedInStatus(false);
    });
  }

  logout() {
    this.userData.logout().then(() => {
      return this.router.navigateByUrl('/login');
    });
  }

  openTutorial() {
    this.menu.enable(false);
    this.storage.set('ion_did_tutorial', false);
    this.router.navigateByUrl('/tutorial');
  }
}
